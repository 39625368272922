@use "../../styles/colors.scss" as *;

.formSectionContainer {
  display: flex;
  padding: 5%;
}

.headingContainer {
  width: 35%;
  padding-right: 3%;
  text-align: left;
  position: sticky;
}

.formContainer {
  width: 65%;
  background-color: white;
  box-shadow: 0px 2px 4px #68686835;
  border-radius: 1rem;
}

.headingContainer > h1 {
  color: $grape-lime-green;
}
