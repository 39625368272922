@use "../../../../styles/colors.scss" as *;

/* Buttons */
.slideshowDot {
  display: inline-block;
  height: 0.55rem;
  width: 0.55rem;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  margin: 0 5px;
  margin-top: 2.5rem;
  background-color: #aeaeaeb9;
}

.slideshowDot.active {
  background-color: $grape-lime-green;
}

@media (max-width: 800px) {
  .slideshowDot {
    border-radius: 30px;
    width: 8%;
    height: 4px;
  }
}
