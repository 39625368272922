@import "../../styles/colors.scss";

.container {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.section2,
.section1 {
  width: 100%;
  padding: 136px 0;
}

.section2 {
  background-color: $grape-dark-grey;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 70%;
  max-width: 1180px;
}

.bigText {
  width: 80%;
}

@media (max-width: 800px) {
  .container {
    margin-top: 60px;
  }

  .wrapper {
    width: 90%;
  }

  .section2,
  .section1 {
    width: 100%;
    padding: 48px 0;
  }
}
