@import "../../styles/colors.scss";
@import "../../styles/typography.scss";

.container {
  display: flex;
  flex-direction: column;
  padding: 2em 0em 3em;
  border-bottom: 1px solid $grape-grey;
}

.container :nth-last-child(-n + 2) {
  border-bottom: none;
}

.wrapper {
  padding-bottom: 1em;
}

.title {
  color: $grape-dark-green;
  text-align: justify;
}

@media (max-width: 800px) {
  .container {
    padding: 1em 0em 1em;
    border-bottom: 3px solid $grape-grey;
  }

  .container :nth-last-child(-n + 2) {
    border-bottom: initial;
  }

  .title {
    text-align: left;
    font-size: 1.4rem;
  }
}
