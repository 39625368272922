@import "../../styles/typography.scss";
@import "../../styles/colors.scss";

.sectionHeadingContainer {
  margin: 0;
  text-align: left;
  width: 100%;
}

.sectionHeadingContainer > p {
  margin: 0;
  margin-bottom: 2rem;
}

.heading {
  text-align: left;
  font-size: 3.35rem;
  line-height: 69px;
  margin-bottom: 40px;
  width: auto;
}

.title {
  color: $grape-dark-grey-2;
  line-height: 28px;
  font-weight: 400;
  color: #000;
  margin-bottom: 16px;
  margin: 0 0 0.5rem;
}

@media (max-width: 800px) {
  .heading {
    font-size: 1.8rem;
    line-height: 45px;
    margin: 0px;
    padding-bottom: 10px;
  }

  .title {
    font-size: 1.2rem;
  }
}

.scrollTransition {
  transition: opacity 1.4s, transform 0.8s cubic-bezier(0.12, 0.7, 0.54, 0.99);
}
