@import "../../styles/colors.scss";

.peopleCard {
  position: relative;
  height: 150px;
  width: 200px;
  background-color: white;
  padding: 1.5rem;
  border-radius: 1rem;
  border: 1px solid #00000012;
  margin: 1rem;
  cursor: pointer;
  box-shadow: 0px 3px 8px rgba(38, 38, 38, 0.1);

  &:hover {
    box-shadow: 0 6.7px 5.3px rgba(0, 0, 0, 0.048),
      0 12.5px 10px rgba(0, 0, 0, 0.06);
  }
}

.profile {
  height: 100px;
}

.linkIcon {
  height: 10px;
}

.linkedInIcon {
  height: 30px;
  position: absolute;
  right: 20px;
  top: 10px;
}

.peopleCard > img {
  border-radius: 1rem;
}

p {
  margin: 0;
  margin-top: 1rem;
}

a.url:link {
  text-decoration: none;
  color: black;
}

a.url:visited {
  text-decoration: none;
  color: black;
}

a.url:hover {
  text-decoration: underline;
}
