@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

$font: "Poppins", sans-serif;

h1 {
  font-size: 64px;
  line-height: 80px;
  font-family: $font;
  font-weight: 600;
}
h2 {
  font-size: 48px;
  line-height: 64px;
  font-family: $font;
  font-weight: 600;
}

h3 {
  font-size: 32px;
  line-height: 48px;
  font-family: $font;
  font-weight: 600;
}

h4 {
  font-size: 24px;
  line-height: 36px;
  font-family: $font;
  font-weight: 600;
}

h5 {
  font-size: 20px;
  line-height: 30px;
  font-family: $font;
  font-weight: 600;
}

h6 {
  font-size: 18px;
  line-height: 28px;
  font-family: $font;
  font-weight: 600;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
}

.subtitle1 {
  font-size: 16px;
  line-height: 24px;
  font-family: $font;
  font-weight: 600;
  margin: 0px;
}

.subtitle2 {
  font-size: 14px;
  line-height: 22px;
  font-family: $font;
  font-weight: 600;
}

.body1 {
  font-size: 16px;
  line-height: 24px;
  font-family: $font;
  font-weight: 500;
}

.body2 {
  font-size: 14px;
  line-height: 22px;
  font-family: $font;
  font-weight: 500;
}

.caption {
  font-size: 12px;
  line-height: 18px;
  font-family: $font;
  font-weight: 500;
}

.overline {
  font-size: 12px;
  line-height: 18px;
  font-family: $font;
  font-weight: 700;
  letter-spacing: 1.2em;
}

.ButtonLg {
  font-size: 14px;
  line-height: 26px;
  font-family: $font;
  font-weight: 400;
}

.ButtonMd {
  font-size: 13px;
  line-height: 24px;
  font-family: $font;
  font-weight: 400;
}

.ButtonSm {
  font-size: 12px;
  line-height: 22px;
  font-family: $font;
  font-weight: 400;
}

.ButtonMain {
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  font-family: $font;
}
