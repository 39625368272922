@use "../../styles/colors.scss" as *;
@use "../../styles/typography.scss" as *;

.footerContainer {
  color: white;
  padding-bottom: 0;
  background-color: black;
}

.footerLine {
  width: 100%;
  background-color: $grape-lime-green;
  height: 3px;
}

.footerContentContainer {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0 0.75rem 0;
  align-items: center;
  margin: 0 1rem;
}

.footerContentContainer > img {
  height: 3rem;
}

.footerSocialsContainer {
  display: flex;
  align-items: center;
}

.footerSocialsContainer > img {
  margin: 0 0.5rem;
  width: 1.75rem;
}

.footerSocialsContainer > p {
  padding-left: 1.5rem;
  font-weight: 200;
  font-size: 1rem;
  margin: 0;
}
