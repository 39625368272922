@import "../../styles/typography.scss";
@import "../../styles/colors.scss";

.container {
  display: flex;
  box-shadow: 0px 2px 4px #26262635;
  border-radius: 10.6021px;
  padding: 12px;
  background-color: $grape-white-text;
  cursor: pointer;
  width: auto;
  height: 100px;

  &:hover {
    box-shadow: 0 6.7px 5.3px #0000000c, 0 12.5px 10px #0000000f;
  }
  transition: 200ms;
}

.image {
  width: 90px;
  height: auto;
  stroke-width: 3px;
}

.header {
  display: flex;
  width: 100%;
  column-gap: 1em;
  align-items: center;
  color: $grape-black-text;
}

.text {
  font-size: 16px;
  line-height: 24px;
  font-family: $font;
  font-weight: 500;
  margin: 0px;
  white-space: nowrap;
}

@media (max-width: 800px) {
  .container {
    width: fit-content;
    padding: 10px 8px;
    height: 50px;
    position: relative;
  }

  .image {
    height: auto;
    width: 50px;
  }

  .text {
    display: none;
  }

  .header p {
    text-align: center;
  }
}
