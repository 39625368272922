@import "../../styles/colors.scss";

.container {
  background-color: #dddddd;
  box-shadow: 0 0 0 50vmax rgba(0, 0, 0, 0.5);
  position: fixed;
  display: flex;
  justify-content: center;
  right: 0;
  bottom: 30%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1300;
}

.modal {
  gap: 0.4rem;
  width: 800px;
  padding: 0px 0px 1.3rem;
  min-height: 500px;
  position: absolute;
  top: 20%;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 15px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 50px;
  border-radius: 10px;
  background: $grape-lime-green;
  padding: 7px;
}

.content {
  display: flex;
  height: auto;
  height: 550px;
  display: flex;
  overflow-y: scroll;
  background: white;
  padding: 15px 0;
  line-height: 1.2;
  text-align: center;
}

.overflowScrollGradient {
  position: relative;
  display: flex;
}
.overflowScrollGradient::before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  background: linear-gradient(white, rgba(255, 255, 255, 0.001));
  height: 100px;
}
.overflowScrollGradient::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  background: linear-gradient(transparent, white);
  pointer-events: none;
}

@media (max-width: 800px) {
  .container {
    height: auto;
    overflow: hidden;
    height: 80%;
    overflow-y: scroll;
    top: 10%;
  }

  .header {
    border-radius: 0px;
  }

  .modal {
    width: 100%;
    top: 0;
    border-radius: 0px;
  }
}
