@use "../../styles/colors.scss" as *;
@use "../../styles/typography.scss" as *;

.container {
  display: flex;
  flex-direction: row;
  height: fit-content;
  column-gap: 1em;
  width: 100%;
  z-index: 1;
  padding: 10px;
  position: fixed;
  z-index: 5;
  background-color: $grape-black;
  box-shadow: 0px 0px 8.83509px 0.883509px #000000;
}

.content {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  column-gap: 1.5em;
}

.navcontainer {
  display: flex;
  padding: 10px;
  justify-content: flex-end;
  column-gap: 2em;
  width: 100%;
}

.grapeLogo {
  opacity: 0;
  position: absolute;
  left: 55px;
  top: 23%;
  transition: 500ms;
  width: 40px;
  height: auto;
}

.logo {
  position: absolute;
  left: 50px;
  top: 17.5%;
  opacity: 1;
  width: 180px;
  height: auto;
  margin: 5px;
  transition: 500ms;
}

.logoContainer {
  width: 100%;
  display: flex;
  align-items: center;
}

.combinedLogo {
  width: 250px;
  height: fit-content;
}

.combinedLogo:hover {
  .logo {
    opacity: 0;
  }

  .grapeLogo {
    opacity: 1;
    left: 100px;
  }
}

.actions {
  margin: 5px;
  display: flex;
  width: fit-content;
  justify-content: flex-end;
  padding: 10px 14px;
}

a.activeNavLink,
a.navLink {
  text-decoration: none;
  font-size: 1.2rem;
  white-space: nowrap;
}

a.activeNavLink {
  color: $grape-lime-green;
}

a.navLink {
  color: $grape-beige;

  &:hover {
    color: $grape-lime-green;
  }
}

@media (max-width: 800px) {
  .navcontainer {
    display: block;
    column-gap: normal;
    text-align: right;
  }
  .content {
    width: 100%;
    column-gap: normal;
  }
  a.activeNavLink,
  a.navLink {
    padding-right: 20px;
    font-size: 1rem;
  }
  .logoContainer {
    max-width: 10px;
    .logo {
      display: none;
    }
    .grapeLogo {
      position: fixed;
      display: block;
      opacity: 1;
      left: 15px;
      top: 5px;
    }
  }
  .actions {
    display: none;
  }
  .combinedLogo:hover {
    .grapeLogo {
      left: 15px;
    }
  }
}

@media (max-width: 1024px) {
  a.navLink,
  a.activeNavLink {
    font-size: 1rem;
    white-space: nowrap;
  }

  .getstarted {
    height: 50px;
    width: 133px;
  }
}
