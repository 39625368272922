@use "./typography.scss" as *;
@use "./colors.scss" as *;

.app {
  text-align: center;
  font-family: $font;
  display: flex;
  flex-direction: column;
}

html {
  background-color: $grape-light-grey;
}
