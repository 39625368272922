@use "../../styles/colors.scss" as *;
@use "../../styles/typography.scss" as *;

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  border-radius: 21px;
  width: 184px;
  @extend .ButtonMd;
  height: 50px;
}

.large {
  height: 50px;
  width: 159px;
  @extend .ButtonLg;
}

.medium {
  width: 133px;
  height: 50px;
  @extend .ButtonMd;
}

.small {
  width: 89px;
  height: 50px;
  @extend .ButtonSm;
}

.primary {
  background-color: $grape-beige;
  color: $grape-black-text;
  border: 0;

  &:hover {
    background-color: $grape-dark-beige;
  }
}

.secondary {
  background-color: $grape-black;
  color: $grape-beige;
  border: 1.5px solid $grape-beige;

  &:hover {
    background-color: $grape-beige;
    color: $grape-black-text;
  }
}

.tertiary {
  background-color: $grape-dark-green;
  color: $grape-white-text;
  border: 0;
  &:hover {
    background-color: $grape-lime-green;
    color: $grape-black-text;
  }
}

.quaternary {
  background-color: $grape-dark-grey;
  color: $grape-white-text;
  border: 1.5px solid $grape-dark-grey;
  @extend .ButtonMain;
  width: 200px;
  height: 50px;

  &:hover {
    background-color: $grape-black;
    color: $grape-beige;
    border: 1.5px solid $grape-dark-grey;
  }
}

.mobileNav {
  background-color: transparent;
  border: 0;
  width: 90px;
  height: 45px;
  color: $grape-beige;
  @extend .ButtonMd;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:hover {
    color: $grape-lime-green;
  }
}

.iconButton {
  display: flex;
  align-items: center;
  flex-direction: column;
  svg {
    opacity: 0;
    width: 0em;
    height: 0em;
    transition: 200ms;
  }

  &:hover {
    svg {
      width: 2em;
      height: 2em;
      opacity: 1;
      transition: 200ms;
    }
  }
}

.iconRight {
  flex-direction: row-reverse;
  column-gap: 1em;
  justify-content: center;
}

.iconLeft {
  flex-direction: row;
  column-gap: 1em;
  justify-content: center;
}
