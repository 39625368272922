@import "../colors.scss";

.contentContainer {
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  margin: auto;
  overflow: hidden;
}

.contentContainer > p {
  text-align: left;
  font-weight: lighter;
  font-size: 1.15rem;
}

.container {
  margin-top: 100px;
}

.section1 {
  width: 90%;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 5%;
}
.section2 {
  width: 100%;
  background-color: $grape-dark-grey;
  padding: 5%;
}

@media (max-width: 800px) {
  .container {
    margin-top: 60px;
  }

  .section2 {
    padding: 0;
  }
}
