@use "../../styles/colors.scss" as *;

.iconButton {
  width: 20px;
  height: 20px;
  padding: 8px;
  border-radius: 50%;
  border: 1px solid transparent;
  cursor: pointer;
  pointer-events: auto;

  &:hover {
    background-color: rgba(0, 0, 0, 0.087);
  }

  &:active {
    background-color: #fff;
    border: 1px solid $grape-black;
    box-shadow: inset 10px 15px 20px 1px rgb(219 219 219);
  }
}
