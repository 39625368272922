.slide {
  display: inline-block;
  width: 95%;
  padding: 0 0 3% 5%;
  white-space: break-spaces;
}

.slide > h1 {
  font-size: 3.5vw;
  color: white;
  margin: 0;
  text-align: left;
  line-height: normal;
}

.slide.italics > h1 {
  font-style: italic;
}

@media (max-width: 800px) {
  .slide > h1 {
    font-size: 1.45rem;
  }
}
