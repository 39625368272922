@import "../../styles/colors.scss";

.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

@media (max-width: 800px) {
  .container {
    margin-top: 60px;
  }
}
