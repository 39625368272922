@import "../../styles/colors.scss";

.container {
  color: $grape-black-text;
  margin: auto;
  text-align: left;
}

.grid {
  display: grid;
  grid-template-columns: auto auto;
  gap: 3em;
  width: 80%;
}

.heading {
  color: $grape-black-text;
  margin-bottom: 0px;
}

@media (max-width: 800px) {
  .grid {
    grid-template-columns: auto;
    gap: 0;
    width: 90%;
    margin: auto;
  }

  .heading {
    color: $grape-black-text;
    margin-bottom: 0px;
    margin: 1rem 5% 0 5%;
    font-size: 1.8rem;
  }
}
