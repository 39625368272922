.fadeInSection {
  opacity: 0;
  transform: translateY(100px);
  visibility: hidden;
  transition: opacity 1.2s ease-out, transform 0.4s ease-out;
  will-change: opacity, visibility;
  width: 100%;
}

.isVisible {
  opacity: 1;
  transform: none;
  visibility: visible;
}
