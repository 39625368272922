.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0;
}

.section2 {
  width: 100%;
  padding: 136px 0;
}

.section1 {
  align-items: flex-start;
  max-width: 90rem;
  margin: 0 auto;
  padding: 240px 106px 96px 118px;
}

.sectionImgContainer {
  width: 50%;
}

.sectionContent {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.img {
  height: 100%;
  width: 450px;
}
