@import "../../styles/typography.scss";
@import "../../styles/colors.scss";

.container {
  display: flex;
  box-shadow: 0px 2px 4px #68686835;
  padding: 12px;
  background-color: $grape-white-text;
  cursor: pointer;
  width: auto;
  &:hover {
    box-shadow: 2px 5px 10px 4px #0400151b;
    z-index: 10;
  }
  transition: 200ms;
}

.icon {
  width: 38px;
  height: 38px;
  stroke-width: 3px;
}

.header {
  display: flex;
  width: 100%;
  column-gap: 1em;
  align-items: center;
  color: $grape-black-text;
}

.tileTitle {
  font-size: 16px;
  line-height: 24px;
  font-family: $font;
  font-weight: 500;
  margin: 0px;
  white-space: nowrap;
}

.selected {
  background-color: $grape-lime-green;
  box-shadow: 0 10px 18px rgba(4, 0, 21, 0.104349);
  z-index: -2;
}

@media (max-width: 800px) {
  .container {
    padding: 10px 8px;
    position: relative;
    border-radius: 10px;
    box-shadow: 0px 1.5px 3px #68686835;
    &:hover {
      box-shadow: 0px 1.5px 3px #00000035;
    }
  }

  .icon {
    display: block;
    position: absolute;
    opacity: 0.05;
    height: 80%;
    right: 7px;
  }

  .tileTitle {
    font-size: 14px;
  }

  .header p {
    text-align: center;
  }
}
