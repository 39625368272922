@use "../../styles/colors.scss" as *;
@use "../../styles/typography.scss" as *;

.container {
  display: flex;
  flex-direction: column;
  align-items: left;
  row-gap: 1em;
}

.children {
  display: flex;
  flex-direction: column;
  column-gap: 1em;
  width: 40%;
}

.heading {
  color: $grape-black-text;
}

.header {
  display: flex;
  width: 100%;
}

.contentContainer {
  display: flex;
  align-items: center;
  width: 60%;
}

.contentWrapper {
  position: relative;
  width: 700px;
  height: 100%;
}
.content {
  opacity: 1;
  visibility: visible;
  text-align: left;
  margin: 0 80px 0 0;
}

.body1 {
  color: $grape-black-text;
}

.contentActions {
  margin-top: 24px;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 800px) {
  .children {
    display: grid;
    grid-template-columns: auto auto;
    width: 100%;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
  }

  .heading {
    font-size: 1.8rem;
    margin-bottom: 0;
  }

  .content {
    width: auto;
    text-align: left;
    margin: 0px;
  }

  .contentContainer {
    width: 100%;
  }

  .wrapper {
    display: flex;
    flex-direction: column-reverse;
  }
}
