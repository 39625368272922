@import "../colors.scss";

.container {
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

.peopleContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.text {
  text-align: left;
}

@media only screen and (max-width: 850px) {
  .peopleContainer {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
}

.wrapper {
  display: flex;
  flex-direction: row-reverse;
  width: 90%;
  margin: auto;
  padding: 10px;
  gap: 16px;
}

@media (max-width: 800px) {
  .wrapper {
    max-height: 650px;
    flex-direction: column;
    display: flex;
  }

  .contentContainer {
    width: 80%;
  }
}
