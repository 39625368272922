@use "../../styles/colors.scss" as *;
@use "../../styles/typography.scss" as *;

/* Slideshow */
.slideshow {
  margin: 0 auto;
  overflow: hidden;
  width: 100%;
  position: relative;
  background-color: $grape-black;
}

.slideshow > h1 {
  font-size: 4vw;
  color: $grape-lime-green;
  margin: 0;
  text-align: left;
  padding: 5% 0 0 5%;
  line-height: normal;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.slideshow > button {
  margin-left: 5%;
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}

.action {
  padding: 0 0 0 5%;
}

.slideshowDots {
  padding-bottom: 1em;
}

.headerImage {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  left: 60vw;
  top: 1.7em;
  width: fit-content;
  z-index: 1;
  animation: fadein 1s;
}

.carouselWithImage {
  padding-bottom: 7em;
}

@media (max-width: 800px) {
  .slideshow > h1 {
    font-size: 1.7rem;
  }
  .action {
    padding-top: 20px;
  }
  .headerImage {
    display: none;
  }
  .carouselWithImage {
    padding-bottom: 0em;
  }
}
