.container {
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  overflow: hidden;
  width: 100%;
}

.subtitle {
  text-align: left;
  margin-bottom: 1em;
}

@-webkit-keyframes reviewCardAnim1 {
  0% {
    -webkit-transform: translateX(-52%);
    transform: translateX(-52%);
  }

  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes reviewCardAnim1 {
  0% {
    -webkit-transform: translateX(-52%);
    transform: translateX(-52%);
  }

  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes reviewCardAnim2 {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(-52%);
    transform: translateX(-52%);
  }
}

@keyframes reviewCardAnim2 {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(-52%);
    transform: translateX(-52%);
  }
}

.wrapper1 {
  animation: reviewCardAnim1 20s ease-in-out infinite alternate;
}

.wrapper1,
.wrapper2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: max-content;
  column-gap: 1em;
  padding-bottom: 1em;
}

.wrapper2 {
  animation: reviewCardAnim2 20s ease-in-out infinite alternate;
}
