$grape-dark-green: #2d825f;
$grape-lime-green: #c6e84f;
$grape-beige: #f6e7d7;
$grape-black: #1c191a;
$grape-black-bg: #070707;
$grape-black-text: #1c191b;
$grape-dark-beige: #ffcf9c;
$grape-light-beige: #f6e7d7;
$grape-dark-grey: rgba(226, 226, 226, 0.29);
$grape-white-text: #ffffff;
$grape-lime-light-green: #d6ee81;
$grape-nav-bar-black: #0c0c0c;
$grape-light-grey: #f8f8f8;
$grape-grey: #e7e7e7;
$grape-dark-grey-2: #757575;
